


























































































import { Observer } from 'mobx-vue'
import { Component, Vue, Inject } from 'vue-property-decorator'
import { walletStore } from '@/stores/wallet-store'
import AddWinnerViewModel from '../viewmodels/add-winner-viewmodel'

@Observer
@Component({
  components: {
    Stepper: () => import('../components/stepper.vue')
  }
})
export default class AddWinnerConfirm extends Vue {
  @Inject() vm!: AddWinnerViewModel
  wallet = walletStore

  async sending() {
    this.vm.setCurrentStep(3)
    await this.vm.handleSending()
  }
}
